import React from 'react';
import Lottie, {useLottie} from 'lottie-react';
import animacion from '../Animaciones/61067-website-design.json';

const Example = () => {
  const options = {
    animationData: animacion,
    loop: true,
    autoplay: true,
  };
 
  const { View } = useLottie(options);
 
  return View;
};
 
export default Example

