import React from 'react';
import Animacion from './Components/Animacion';

import './App.css';

function App() {
  return (
    <div className="contenedor">
      <div className="contenido">
        <h3>Sitio Web en Construcción</h3>
        <div className="animate">
          <Animacion />
        </div>          
        <h4>Editorial Mi Nuevo Mundo</h4>
      </div>
    </div>
  );
}
export default App;
